import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import { Reauth as ReauthPage } from "@parallel-domain/pd-theme"
import { signIn, SignInResponse } from "next-auth/react"
import axios from "axios"
import { toast } from "react-toastify"
import parse from "html-react-parser"

const Reauth = () => {
  const [loading, setLoading] = useState<"email" | "sso" | "code" | "redirect" | "">("")

  const router = useRouter()

  // Sign in user on redirect
  useEffect(() => {
    const signInWithSSO = async () => {
      setLoading("code")

      const { code, state: callback } = router.query

      // Remove code GET parameter from URL
      router.replace(router.pathname, undefined, { shallow: true })

      const response: SignInResponse | undefined = await signIn("sso", { code, redirect: false })

      if (response?.ok) {
        setLoading("redirect")

        if (callback) {
          router.push(callback as string)
        }
      } else {
        toast.error(<>{parse(response?.error || "Something went wrong")}</>)
        setLoading("")
      }
    }

    if (router?.query?.code) {
      signInWithSSO()
    }
  }, [router])

  const handleSubmitSSO = async (formData: { email: string }) => {
    setLoading("sso")

    try {
      const response: { data: { url: string } } = await axios.post("/api/auth/sso", formData)

      const delimiter = response.data.url.includes("?") ? "&" : "?"
      const url = `${response.data.url}${delimiter}state=${router.asPath}`

      // Redirect to sso provider URL
      window.location.replace(url)
    } catch (e) {
      const error = e as { response: { data: { message: string } } }
      toast.error(<>{parse(error.response.data.message)}</>)
      setLoading("")
    }
  }

  const handleSubmitEmailPassword = async (formData: { email: string; password: string }) => {
    setLoading("email")

    const response: SignInResponse | undefined = await signIn("credentials", { ...formData, redirect: false })

    if (!response?.ok) {
      toast.error(<>{parse(response?.error || "Something went wrong")}</>)
      setLoading("")
    }
  }

  return (
    <ReauthPage
      loading={loading}
      linkComponent={Link}
      onSubmitEmailPassword={handleSubmitEmailPassword}
      onSubmitSSO={handleSubmitSSO}
    />
  )
}

export default Reauth
