import { useEffect } from "react"
import { useRouter } from "next/router"

// import app components
import useReleases from "../store/useReleases"
import { SAMPLE_RELEASE_VERSION } from "../constants"
import useCustomSession from "features/authentication/hooks/useSession"
import formatVersion from "../utils/formatVersion"

const useReleasesQuery = () => {
  const { data: session } = useCustomSession()

  const router = useRouter()

  const activeRelease = useReleases((state) => state.activeRelease)
  const setActiveRelease = useReleases((state) => state.setActiveRelease)

  const isEnabled =
    !!session?.user?.organization?.slug &&
    !!session?.user?.organization?.capabilities.includes("viewStepAccess") &&
    !session?.error

  /*
   * Set initial release version
   */
  useEffect(() => {
    if (!session || activeRelease) return

    if (!isEnabled) {
      return setActiveRelease(SAMPLE_RELEASE_VERSION)
    }

    let query = ""
    if (router.query.version) {
      query = router.query.version as string
    } else if (window.localStorage.getItem("release")) {
      query = window.localStorage.getItem("release") as string
    }

    if (
      query &&
      session.user.organization &&
      // Make sure that query param exists in org releases
      session.user.organization.releases.find((release) => formatVersion(release, true) === query || release === query)
    ) {
      setActiveRelease(query)
    } else {
      setActiveRelease(SAMPLE_RELEASE_VERSION)
    }
  }, [router.query.version, !!session?.user?.organization, isEnabled, activeRelease])
}

export default useReleasesQuery
