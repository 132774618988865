"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors = {
    error: "#d32f2f",
    success: "#7EB845",
    info: "#216c8e",
    warning: "#ffa726",
    gradients: {
        primary: "linear-gradient(92.61deg, #ffba5c 5.96%, #ff8747 102.51%)",
    },
    primary: {
        600: "rgba(255, 172, 60, 0.6)",
        700: "#D39E54",
        900: "#ffac3c",
        1000: "#F9982E",
    },
    primaryContrast: {
        600: "#111a20",
        700: "#111a20",
        900: "#111a20",
        1000: "#111a20",
    },
    secondary: {
        600: "#243341",
        700: "#1D2B37",
        800: "#162530",
        850: "#131f27",
        900: "#111a20",
    },
    secondaryHover: {
        600: "rgba(255, 255, 255, 0.04)",
        700: "rgba(255, 255, 255, 0.04)",
        800: "rgba(255, 255, 255, 0.04)",
        900: "rgba(255, 255, 255, 0.04)",
    },
    secondarySelected: {
        600: "rgba(255, 255, 255, 0.08)",
        700: "rgba(255, 255, 255, 0.08)",
        800: "rgba(255, 255, 255, 0.08)",
        900: "rgba(255, 255, 255, 0.08)",
    },
    tertiary: {
        600: "#B7DEFF",
        700: "#7ABAEA",
    },
    text: {
        100: "#d4d6d9",
        600: "#BCC2C5",
        700: "#77828e",
    },
    review: {
        700: "#424670",
        900: "#5b5caf",
    },
    completed: {
        700: "#788e86",
        900: "#94b6a3",
    },
    cancelled: {
        700: "#a24e45",
        900: "#eb5140",
    },
    icons: {
        100: "#d4d6d9",
        700: "#707A81",
    },
    border: {
        100: "rgba(217, 217, 217, .4)",
        700: "#525C66",
        800: "#33404e",
    },
    white: {
        600: "rgba(255, 255, 255, 0.04)",
        900: "#ffffff",
    },
    inputDisabled: {
        100: "rgba(217, 217, 217, .4)",
        900: "#404a55",
    },
};
exports.default = colors;
