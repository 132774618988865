import create from "zustand"
import produce from "immer"

// import app components
import type { Release } from "features/releases/types"

type Store = {
  activeRelease: string
  releases: Release[] | null
  setActiveRelease: (release: string) => void
  setReleases: (releases: Release[]) => void
}

const useUsers = create<Store>((set, get) => ({
  activeRelease: "",
  releases: null,
  setActiveRelease: (release) => {
    set(
      produce((state: Store) => {
        state.activeRelease = release
      })
    )

    window.localStorage.setItem("release", release)
  },
  setReleases: (releases) => {
    set(
      produce((state: Store) => {
        state.releases = releases
      })
    )

    if (!get().activeRelease || !releases.find((release) => release.name === get().activeRelease)) {
      const latestRelease = releases.reduce(
        (prev, curr) => {
          return curr.name > prev.name ? curr : prev
        },
        { name: "" }
      ).name

      set(
        produce((state: Store) => {
          state.activeRelease = latestRelease
        })
      )
    }

    // Update local storage item. We do it here because the item could have been set from query parameter or latest release
    window.localStorage.setItem("release", get().activeRelease)
  },
}))

export default useUsers
