const formatVersion = (version: string, includeVersionPrefix = false): string => {
  // Extract the MAJOR.MINOR.PATCH version using a regex pattern
  const matches = version.match(/v?(\d+\.\d+\.\d+)/)

  if (!matches) {
    return ""
  }

  const result = `${matches[1]}`

  if (includeVersionPrefix) {
    return `v${result}`
  }

  return result
}

export default formatVersion
