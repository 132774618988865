// Directory of readme.com documentation`
export const README_BASE_DIRECTORY = "docs"
export const README_ENTRY_PAGE = "getting-started"

// Base urls to access PD SDK documentation
export const PD_SDK_BASE_URL = "https://parallel-domain.github.io/pd-sdk"
export const PD_SDK_INTERNAL_BASE_URL = "https://pd-sdk-documentation.netlify.app"
export const PD_SDK_BASE_DIRECTORY = "pd-sdk"
export const PD_SDK_VERSION_INDEX = {
  "v2.7.0": "releases-r2-7",
  "v2.7.1": "releases-r2-7",
  "v2.0.0": "releases-r2",
  "v3.2.0": "releases-r3",
  "v3.3.0": "releases-r3-3",
}

export const DOC_NOT_FOUND = {
  title: "Page not found",
  content: "Please select another page via the sidebar menu.",
  nav: [],
}
