import React from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { colors, Typography } from "@parallel-domain/pd-theme"
import { useRouter } from "next/router"

// import app components
import useReleases from "../store/useReleases"
import formatVersion from "../utils/formatVersion"
import * as DOCS_CONSTANTS from "features/docs/constants"
import useCustomSession from "features/authentication/hooks/useSession"

const ReleaseToggle = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { data: session } = useCustomSession()

  const router = useRouter()

  const activeRelease = useReleases((state) => state.activeRelease)
  const setActiveRelease = useReleases((state) => state.setActiveRelease)

  const handleSelectRelease = (name: string) => {
    setActiveRelease(name)
    setAnchorEl(null)

    if (router.asPath.startsWith(`/${DOCS_CONSTANTS.README_BASE_DIRECTORY}/`)) {
      // Navigate back to docs homepage because we can't be sure if page exists
      router.push(`/${DOCS_CONSTANTS.README_BASE_DIRECTORY}/${name}/${DOCS_CONSTANTS.README_ENTRY_PAGE}`)
    } else if (router.asPath.startsWith("/explore")) {
      // Replace version in pathname
      const newPathname = router.asPath.replace(activeRelease, name)
      router.push(newPathname)
    }
  }

  if (!session?.user?.organization?.releases) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          cursor: "pointer",
          py: "8px",
          fontWeight: 700,
          color: colors.text[600],
          background: colors.secondary[700],
          borderRadius: "0.5rem",
          border: 1,
          borderColor: colors.border[800],
          transition: "ease all 0.2s",
          "&:hover": {
            opacity: session.user.organization.releases.length > 1 ? 0.8 : 1,
          },
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
      >
        {activeRelease && (
          <Typography variant="caption" color="inherit">
            {formatVersion(activeRelease, true)}
          </Typography>
        )}
      </Box>

      <Menu
        open={session.user.organization.releases.length > 1 && Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {session.user.organization.releases.map((name) => {
          return (
            <MenuItem key={name} selected={name === activeRelease} onClick={() => handleSelectRelease(name)}>
              {name}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default ReleaseToggle
