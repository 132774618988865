import { useState } from "react"
import axios, { AxiosResponse } from "axios"
import { signOut } from "next-auth/react"
import NextLink from "next/link"
import { useRouter } from "next/router"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { Navbar } from "@parallel-domain/pd-theme"
import type { NavItem } from "@parallel-domain/pd-theme/types"
import { toast } from "react-toastify"
import {
  Compass as ExploreIcon,
  Db as AdminIcon,
  GoogleDocs as DatasetsIcon,
  LogOut as LogoutIcon,
  OpenBook as DocumentationIcon,
  Settings as SettingsIcon,
  Flask as DataLabIcon,
  MessageText as FeedbackIcon,
} from "iconoir-react"

// import app components
import useLayout from "../../store/useLayout"
import * as DOCS_CONSTANTS from "features/docs/constants"
import { WpAdminLoginResponse } from "features/database/types"
import logout from "features/authentication/api/logout"
import useCustomSession from "features/authentication/hooks/useSession"
import Feedback from "components/feedback"
import ReleaseToggle from "features/releases/components/ReleaseToggle"
import useReleases from "features/releases/store/useReleases"
import * as RELEASE_CONSTANTS from "features/releases/constants"

export type Nav = {
  isVisible?: boolean
  children: React.ReactNode | React.ReactNode[]
}

const Nav = (props: Nav) => {
  const { isVisible = true, children } = props

  const activeRelease = useReleases((state) => state.activeRelease)

  const [logoutLoading, setLogoutLoading] = useState(false)
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false)

  const { data: session } = useCustomSession()

  const router = useRouter()

  const menu = useLayout((state) => state.menu)
  const setMenu = useLayout((state) => state.setMenu)

  const getName = () => {
    // Try to render first and last name
    if (session?.user?.firstName && session?.user?.lastName) {
      return `${session.user.firstName} ${session.user.lastName}`

      // If not available try to render only first name
    } else if (session?.user?.firstName) {
      return session.user.firstName

      // And if that's not available try to render email
    } else if (session?.user?.email) {
      return session.user.email
    }

    return ""
  }

  const handleAdminLogin = async () => {
    try {
      const response: AxiosResponse<WpAdminLoginResponse> = await axios.post("/api/auth/wpAdminLogin")
      window.open(response.data.wpAdminUrl, "_blank")
    } catch (e) {
      const error = e as { response: { data: { message: string } } }
      toast.error(error?.response?.data?.message || "Error fetching credentials")
    }
  }

  const handleLogout = async () => {
    setLogoutLoading(true)
    await logout()
    signOut({ callbackUrl: "/auth/login" })
  }

  const handleOpenFeedbackDialog = () => {
    setFeedbackFormOpen(true)
  }

  const handleCloseFeedbackDialog = () => {
    setFeedbackFormOpen(false)
  }

  const getNavItems = () => {
    const items: NavItem[] = [
      {
        label: "Datasets",
        icon: <DatasetsIcon />,
        href: "/datasets",
        active: router.asPath.includes("/datasets"),
      },
    ]

    if (session?.user?.organization) {
      items.unshift({
        label: "Docs",
        icon: <DocumentationIcon />,
        href: `/${DOCS_CONSTANTS.README_BASE_DIRECTORY}/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/${
          DOCS_CONSTANTS.README_ENTRY_PAGE
        }`,
        active: router.asPath.includes("/docs"),
      })
    }

    if (session?.user?.organization?.capabilities.includes("viewStepAccess")) {
      items.unshift({
        label: "Data Lab",
        icon: <DataLabIcon />,
        href: "/data-lab/dashboard",
        active: router.asPath.startsWith("/data-lab"),
      })
    }

    if (session?.user?.organization) {
      items.splice(3, 0, {
        label: "Explore",
        icon: <ExploreIcon />,
        href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/locations`,
        active: router.asPath.includes("/explore"),
      })
    }

    items.push({
      label: "Settings",
      icon: <SettingsIcon />,
      href: "/settings/profile",
      active: router.asPath.includes("/settings"),
    })

    // Add admin link in case user is authorized
    if (session?.user?.organization?.capabilities.includes("accessBackend")) {
      items.push({
        label: "Admin",
        icon: <AdminIcon />,
        onClick: handleAdminLogin,
      })
    }

    items.push({
      label: "Feedback",
      icon: <FeedbackIcon />,
      onClick: handleOpenFeedbackDialog,
    })

    // Push logout icon to the end
    items.push({
      label: "Logout",
      icon: logoutLoading ? <CircularProgress size={20} /> : <LogoutIcon />,
      onClick: handleLogout,
    })

    return items
  }

  if (!isVisible) {
    return <>{children}</>
  }

  return (
    <>
      <Navbar
        open={menu}
        onToggleDrawer={() => setMenu(!menu)}
        profile={{ avatarUrl: "", fullName: getName() }}
        organization={session?.user?.organization}
        navItems={getNavItems()}
        linkComponent={NextLink}
        releaseToggle={<ReleaseToggle />}
      >
        <Box sx={{ flex: 1 }}>{children}</Box>
      </Navbar>

      <Feedback open={feedbackFormOpen} onClose={handleCloseFeedbackDialog} />
    </>
  )
}

export default Nav
